import { DataColumns } from '../DataColumns/DataColumns';
import { GridDateCell } from '../../../../Core/Components/Filter/FilterElements';
import { DateRangeFilterCell, MultiViewCalenderFilterCell } from 'smart-react';
/**
 * Generates a list of columns for a grid.
 * @param {object} options - The options for the grid.
 * @returns {Array} - The list of grid columns.
 */
export const GridColumnsList = async ({
  editContentSlider,
  dateRangefilter,
  setDateRangeFilter,
  onEditAppFlow,
}) => {
  return DataColumns({
    editContentSlider,
    DateRangeFilterCell,
    dateRangeFilter: dateRangefilter,
    setDateRangeFilter,
    GridDateCell,
    MultiViewCalenderFilterCell,
    onEditAppFlow,
  });
};

export default GridColumnsList;
