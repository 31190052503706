import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { TESTARGUMENTS } from '../../../../constants/applicationConstants';
import {
  DataTableHoc,
  Loader,
  ErrorFallback,
  deleteFilter,
  applyFilter,
  saveFilters,
} from 'smart-react';
import { GridColumnsList } from '../Components/Filter/FilterElements';
import DataColumns from '../Components/DataColumns/DataColumns';
import {
  ROW_LAYOUTS,
  PAGE_SIZE,
} from '../../../../constants/applicationConstants';
const BaseTestArgumentDataTable = React.lazy(
  () => import('../Components/DataTable/DataTable'),
);
const TestArgumentDataTable = DataTableHoc(BaseTestArgumentDataTable);

/**
 * TestArguments main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The TestArguments component.
 */
const TestArguments = ({ test }) => {
  const [userData, setUserData] = React.useState([]);
  const dataColumnsArray = DataColumns({});
  // used to check API is ready to call
  const [isReady, setIsReady] = React.useState(false);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className="container mx-auto k-px-4">
        <React.Suspense fallback={<Loader />}>
          <TestArgumentDataTable
            test={test}
            deleteFilter={deleteFilter}
            applyFilter={applyFilter}
            saveFilters={saveFilters}
            moduleName={TESTARGUMENTS}
            dataColumns={dataColumnsArray}
            gridColumnsList={GridColumnsList}
            dataTableName={TESTARGUMENTS}
            setIsReady={setIsReady}
            isReady={isReady}
            rowLayoutConfigName={`${TESTARGUMENTS}.DataGrid.RowLayout`}
            pageLengthConfigName={`${TESTARGUMENTS}.DataGrid.ItemsPerPage`}
            dataSet={userData}
            setDataSet={setUserData}
            initialSort={{
              field: 'id',
              dir: 'desc',
            }}
            isCardonMobileView={false}
            total={process.env.DefaultLimit}
            defaultRowLayout={process.env.RowLayout}
            rowLayouts={ROW_LAYOUTS}
            pageSizes={PAGE_SIZE}
          />
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default TestArguments;
