import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Loader, ErrorFallback, TextInput, InputSwitch } from 'smart-react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardBody } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import {
  buildNotification,
  GenerateNotification,
  isValidate,
  isFromValid,
  DescriptionTextArea,
  DropDownInput,
} from 'smart-react';
import { createTestCase, updateTestCase } from '../Services/TestCaseService';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Field } from '@progress/kendo-react-form';
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from '../../../../constants/eventDataTypes';
import { LOOKUP_TYPES } from '../../../../constants/applicationConstants';
import { Error } from '@progress/kendo-react-labels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import TenantDropdown from '../../../Core/Components/TenantDropdown/TenantDropdown';
import CommandEditor from '../../../Core/Components/CommandEditor/CommandEditor';
import { useAuth } from '../../../Core/Context/AuthContext';
import DesktopLayout from '../Components/Layout/DesktopLayout';
import {
  ValidatePermissions,
  findTenant,
  findTenantById,
} from '../../../../Utils/Auth/ValidatePermissions';
import { listTestCase } from '../Services/TestCaseService';
import { SplitButton, SplitButtonItem } from '@progress/kendo-react-buttons';
import TestInput from '../../Test/Components/TestInput/TestInput';
import { Lookup_Selection_Mode } from '../../../../constants/applicationConstants';
import {
  CREATE_MESSAGE,
  UPDATE_MESSAGE,
  ERROR_MESSAGE,
} from '../../../../constants/notificationMessages';
/**
 * Add New Test main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Add New Test component.
 */
const AddTestCase = () => {
  const navigate = useNavigate();
  const { tenantID, getTokensFromStorage } = useAuth(); // Get tenantID using Auth Context
  /**
   * Used to handle multiple tabs
   */
  const [selected, setSelected] = React.useState(0);
  const [detailsDataModified, setDetailsDataModified] = React.useState(false);
  const [tenant, setTenant] = React.useState(findTenantById(tenantID));

  const [isEditor, setIsEditor] = React.useState(false);
  const [isTextArea, setIsTextArea] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [shouldUpdate, setShouldUpdate] = React.useState(false);
  const [currentTest, setCurrentTest] = React.useState();
  const [formData, setFormData] = React.useState({
    tenant_id: tenantID,
    TenantName: tenant?.TenantName,
    uc_ossi_prod_id: 'RP',
    uc_ossi_app_test_id: '',
    uc_ossi_test_case_id: '',
    uc_env_id: '%',
    uc_ossi_pre_exec_cmd: '',
    uc_ossi_descr: '',
    uc_ossi_post_exec_cmd: '',
    uc_ossi_validate_cmd: '',
    uc_ossi_expected_exec_ms: '',
    uc_ossi_expected_lines_cmd: '',
    uc_ossi_grp: '',
    uc_ossi_disable_flg: false,
    uc_ossi_device_grp: '',
    uc_ossi_client_id: '',
  });
  const { state } = useLocation();
  const [testState, setTestState] = React.useState(state);
  const [validateForm, setValidateForm] = React.useState(false);
  const [editorField, setEditorField] = React.useState({
    name: '',
    value: '',
    label: '',
  });
  const groupTypes = JSON.parse(localStorage.getItem(LOOKUP_TYPES?.groupTypes));
  const requiredFields = [
    'uc_ossi_app_test_id',
    'uc_ossi_test_case_id',
    'uc_ossi_descr',
    'TenantName',
  ];
  /**
   * Handle Selected Tab
   * @param {*} e
   */
  const handleSelect = (e) => {
    setSelected(e.selected);
    setShouldUpdate(!shouldUpdate);
  };

  React.useEffect(() => {
    if (state) {
      const { accessToken } = getTokensFromStorage();

      const { dataSet } = state;
      (async () => {
        setLoading(true);
        const response = await listTestCase({
          accessToken: accessToken,
          limit: '50',
          offset: 1,
          filter: [
            {
              ColumnName: 'uc_ossi_app_test_id',
              Op: 'eq',
              ColumnValue: dataSet?.uc_ossi_app_test_id,
            },
            {
              ColumnName: 'tenant_id',
              Op: 'eq',
              ColumnValue: dataSet?.tenant_id,
            },
            {
              ColumnName: 'uc_ossi_prod_id',
              Op: 'eq',
              ColumnValue: dataSet?.uc_ossi_prod_id,
            },
            {
              ColumnName: 'uc_ossi_test_case_id',
              Op: 'eq',
              ColumnValue: dataSet?.uc_ossi_test_case_id,
            },
          ],
          moduleName: 'Tests',
          isServiceWorker: false,
        });
        const testData = response.tests[0];
        setTestState({ ...state, dataSet: testData });
        setFormData(testData);
        setLoading(false);
      })();
      setCurrentTest({ ...dataSet });
      setFormData({ ...dataSet });
      setTenant(findTenant(dataSet));
      if (ValidatePermissions(dataSet)) {
        setDetailsDataModified(true);
      }
      setShouldUpdate(!shouldUpdate);
    } else {
      setDetailsDataModified(true);
    }
  }, [state]);

  /**
   * Update Test id
   */
  React.useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      uc_ossi_app_test_id: currentTest?.uc_ossi_app_test_id,
    }));
  }, [currentTest]);

  /**
   * on update the tenantID update the formData
   */
  React.useEffect(() => {
    if (!state?.edit) {
      let tempTenant = findTenantById(tenantID);
      setTenant(tempTenant);
      setFormData((prevState) => ({
        ...prevState,
        tenant_id: tenantID,
        TenantName: tempTenant?.TenantName,
      }));
    }
  }, [tenantID]);

  /**
   * handle Input Change
   * @e object
   */
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? (checked ? 1 : 0) : value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  /**
   * onSave
   * submit the test data to api
   */
  const onSave = async ({ isExit = false, isContinue = false }) => {
    setValidateForm(true);
    if (!isFromValid({ formData, requiredFields })) {
      return;
    }
    setLoading(true);
    let notificationMetaData = {};
    const data = {
      ...formData,
      uc_ossi_test_case_id: state?.edit
        ? formData?.uc_ossi_test_case_id
        : tenant?.OrganizationPrefix + formData?.uc_ossi_test_case_id,
      uc_ossi_disable_flg: formData?.uc_ossi_disable_flg ? 1 : 0,
    };
    let response;
    let method;

    if (state?.edit) {
      notificationMetaData = UPDATE_MESSAGE;
      method = updateTestCase;
    } else {
      notificationMetaData = CREATE_MESSAGE;
      method = createTestCase;
      setFormData({
        tenant_id: tenantID,
        TenantName: tenant?.TenantName,
        uc_ossi_prod_id: 'RP',
        uc_ossi_app_test_id: '',
        uc_ossi_test_case_id: '',
        uc_env_id: '%',
        uc_ossi_pre_exec_cmd: '',
        uc_ossi_descr: '',
        uc_ossi_post_exec_cmd: '',
        uc_ossi_validate_cmd: '',
        uc_ossi_expected_exec_ms: '',
        uc_ossi_expected_lines_cmd: '',
        uc_ossi_grp: '',
        uc_ossi_disable_flg: false,
        uc_ossi_device_grp: '',
        uc_ossi_client_id: '',
      });
    }

    try {
      setValidateForm(false);
      response = await method({
        data,
        moduleName: 'testcase',
        isServiceWorker: false,
      });

      if (response.Message) {
        GenerateNotification(
          buildNotification({
            title: ERROR_MESSAGE?.title,
            description: response.Message,
            style: ERROR_MESSAGE?.style,
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
      } else if (!response?.IsSuccess) {
        GenerateNotification(
          buildNotification({
            title: ERROR_MESSAGE?.title,
            description: response.StatusDesc,
            style: ERROR_MESSAGE?.style,
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
      } else {
        GenerateNotification(
          buildNotification(notificationMetaData),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
        if (isContinue) {
          navigate(`/autest/testcase`, {
            state: {
              edit: true,
              dataSet: response?.Payload,
            },
          });
        }
        if (isExit) {
          navigate('/autest/testcases');
        }
      }
    } catch (error) {
      GenerateNotification(
        buildNotification(ERROR_MESSAGE),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
    } finally {
      setLoading(false);
    }
  };

  /**
   * onExit
   * back to tests listing screen
   */
  const onExit = () => {
    navigate('/autest/testcases');
  };

  const handleSaveSplit = (e) => {
    const { itemIndex } = e;
    switch (itemIndex) {
      case 0: {
        onSave({ isExit: true });
        break;
      }
      case 1:
        onSave({ isContinue: true });
      default: {
      }
    }
  };
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className='container mx-auto'>
        <React.Suspense fallback={<Loader />}>
          {loading && <Loader />}
          <>
            <div className={`${isEditor ? '' : 'k-d-none'}`}>
              <CommandEditor
                onChange={handleInputChange}
                label={'Syntax*'}
                renderTypes={!detailsDataModified ? 'ReadOnly' : ''}
                validateForm={validateForm}
                formData={formData}
                setFormData={setFormData}
                detailsDataModified={detailsDataModified}
                isEditor={isEditor}
                setIsEditor={setIsEditor}
                editorField={editorField}
                setEditorField={setEditorField}
                smHeight={'100px'}
                lgHeight={'70vh'}
              />
            </div>
            <div className={`${isTextArea ? '' : 'k-d-none'}`}>
              <DescriptionTextArea
                onChange={handleInputChange}
                label={'Description *'}
                renderTypes={!detailsDataModified ? 'ReadOnly' : ''}
                validateForm={validateForm}
                formData={formData}
                setFormData={setFormData}
                detailsDataModified={detailsDataModified}
                isTextArea={isTextArea}
                setIsTextArea={setIsTextArea}
                editorField={editorField}
                setEditorField={setEditorField}
                row={2}
              />
            </div>
            <div
              className={`add-test main-page-section add-page-section ${!isTextArea && !isEditor ? '' : 'k-d-none'}`}
            >
              <Card className='tophead'>
                <CardHeader>
                  <div className='action-buttons-container'>
                    <Link
                      className='action-icon-wrapper !k-d-block'
                      to={`/autest/testcases`}
                    >
                      <FontAwesomeIcon
                        className='k-mr-2 k-pt-3'
                        icon={faArrowLeft}
                      />
                    </Link>
                    <div>
                      <p className='display-text k-mb-0'>
                        Test Case:{' '}
                        {state?.edit
                          ? formData?.uc_ossi_test_case_id
                          : (tenant?.OrganizationPrefix ?? '') +
                            formData?.uc_ossi_test_case_id}
                      </p>
                      {formData?.uc_ossi_descr && (
                        <span className='k-d-block k-mb-2 k-pl-1'>
                          {formData?.uc_ossi_descr}
                        </span>
                      )}
                    </div>
                  </div>
                </CardHeader>
              </Card>
              <Card className='k-mt-3 detail-card'>
                <CardHeader>
                  <h6 className='card-title-secondary'>Details</h6>
                </CardHeader>
                <CardBody className='detail-card'>
                  <div>
                    <Form
                      onSubmit={onSave}
                      render={(formRenderProps) => (
                        <div className='card-content'>
                          <div className='k-d-flex single-field-row single-row-60'>
                            <div
                              className={`field-wrapper ${isValidate(validateForm, formData?.TenantName) ? 'field-invalid' : 'field-valid'}`}
                            >
                              <TenantDropdown
                                textField='TenantName'
                                label='Tenant'
                                dataItemKey='TenantId'
                                className='tenant-dropdown k-align-self-end'
                                tenant={tenant}
                                setTenant={setTenant}
                                defaultItem={{
                                  TenantName: 'Select Tenant ...',
                                  disabled: true,
                                }}
                                editMode={state?.edit ? 'ReadOnly' : ''}
                                filterable={true}
                                tenantFormId='tenant_id'
                                tenantFormName='TenantName'
                                setFormData={setFormData}
                                requiredField={true}
                              />
                              {isValidate(
                                validateForm,
                                formData?.TenantName,
                              ) && <Error>This field cannot be empty</Error>}
                            </div>
                          </div>
                          <div className='k-d-flex single-field-row single-row-60'>
                            <div
                              className={`k-p-2 field-wrapper  ${isValidate(validateForm, formData?.uc_ossi_app_test_id) ? 'field-invalid' : 'field-valid'}`}
                            >
                              <div>
                                <TestInput
                                  currentTest={currentTest}
                                  setCurrentTest={setCurrentTest}
                                  lookupSelectionMode={
                                    Lookup_Selection_Mode.single
                                  }
                                  disabled={state?.edit ? true : false}
                                  requiredField={true}
                                />
                                {isValidate(
                                  validateForm,
                                  formData?.uc_ossi_app_test_id,
                                ) && <Error>This field cannot be empty</Error>}
                              </div>
                            </div>
                            <div
                              className={`field-wrapper ${!state?.edit && 'prefix-field-wrapper'} ${isValidate(validateForm, formData.uc_ossi_test_case_id) ? 'field-invalid' : 'field-valid'}`}
                            >
                              <Field
                                key={'uc_ossi_test_case_id'}
                                id={'uc_ossi_test_case_id'}
                                name={'uc_ossi_test_case_id'}
                                label={'Test Case'}
                                data={formData?.uc_ossi_test_case_id}
                                onChange={handleInputChange}
                                type={'text'}
                                component={TextInput}
                                renderTypes={state?.edit ? 'ReadOnly' : ''}
                                optional={false}
                                requiredField={true}
                                prefix={!state?.edit ? true : false}
                                prefixLabel={tenant?.OrganizationPrefix}
                              />
                              {isValidate(
                                validateForm,
                                formData.uc_ossi_test_case_id,
                              ) && <Error>This field cannot be empty</Error>}
                            </div>
                          </div>
                          <div className='k-d-flex single-field-row single-row-60'>
                            <div
                              className={`field-wrapper field-wrapper-description field-wrapper-100 ${isValidate(validateForm, formData?.uc_ossi_descr) ? 'field-invalid' : 'field-valid'}`}
                            >
                              <DescriptionTextArea
                                onChange={handleInputChange}
                                label={'Description'}
                                renderTypes={
                                  !detailsDataModified ? 'ReadOnly' : ''
                                }
                                validateForm={validateForm}
                                formData={formData}
                                setFormData={setFormData}
                                detailsDataModified={detailsDataModified}
                                isTextArea={isTextArea}
                                setIsTextArea={setIsTextArea}
                                editorField={{
                                  name: 'uc_ossi_descr',
                                  value: formData?.uc_ossi_descr,
                                  label: 'Description',
                                }}
                                setEditorField={setEditorField}
                                row={2}
                                requiredField={true}
                              />
                              {isValidate(
                                validateForm,
                                formData?.uc_ossi_descr,
                              ) && <Error>This field cannot be empty</Error>}
                            </div>
                          </div>
                          <div className='k-d-flex single-field-row single-row-80'>
                            <div className={`field-wrapper`}>
                              <CommandEditor
                                onChange={handleInputChange}
                                renderTypes={
                                  !detailsDataModified ? 'ReadOnly' : ''
                                }
                                validateForm={validateForm}
                                formData={formData}
                                setFormData={setFormData}
                                detailsDataModified={detailsDataModified}
                                isEditor={isEditor}
                                setIsEditor={setIsEditor}
                                editorField={{
                                  name: 'uc_ossi_pre_exec_cmd',
                                  value: formData?.uc_ossi_pre_exec_cmd,
                                  label: 'Pre Execution Command',
                                }}
                                setEditorField={setEditorField}
                                smHeight={'100px'}
                                lgHeight={'70vh'}
                              />
                            </div>
                            <div className={`field-wrapper`}>
                              <CommandEditor
                                onChange={handleInputChange}
                                renderTypes={
                                  !detailsDataModified ? 'ReadOnly' : ''
                                }
                                validateForm={validateForm}
                                formData={formData}
                                setFormData={setFormData}
                                detailsDataModified={detailsDataModified}
                                isEditor={isEditor}
                                setIsEditor={setIsEditor}
                                editorField={{
                                  name: 'uc_ossi_post_exec_cmd',
                                  value: formData?.uc_ossi_post_exec_cmd,
                                  label: 'Post Execution Command',
                                }}
                                setEditorField={setEditorField}
                                smHeight={'100px'}
                                lgHeight={'70vh'}
                              />
                            </div>
                          </div>
                          <div className='k-d-flex single-field-row single-row-80'>
                            <div className={`field-wrapper`}>
                              <CommandEditor
                                onChange={handleInputChange}
                                renderTypes={
                                  !detailsDataModified ? 'ReadOnly' : ''
                                }
                                validateForm={validateForm}
                                formData={formData}
                                setFormData={setFormData}
                                detailsDataModified={detailsDataModified}
                                isEditor={isEditor}
                                setIsEditor={setIsEditor}
                                editorField={{
                                  name: 'uc_ossi_validate_cmd',
                                  value: formData?.uc_ossi_validate_cmd,
                                  label: 'Validation Command',
                                }}
                                setEditorField={setEditorField}
                                smHeight={'100px'}
                                lgHeight={'70vh'}
                              />
                            </div>
                            <div className={`field-wrapper`}>
                              <Field
                                key={'uc_ossi_expected_lines_cmd'}
                                id={'uc_ossi_expected_lines_cmd'}
                                name={'uc_ossi_expected_lines_cmd'}
                                data={formData?.uc_ossi_expected_lines_cmd}
                                onChange={handleInputChange}
                                label={'Expected Command'}
                                type={'text'}
                                component={TextInput}
                                renderTypes={
                                  !detailsDataModified ? 'ReadOnly' : ''
                                }
                                optional={false}
                                fieldWrapperStyles={{
                                  width: '70%',
                                }}
                              />
                            </div>
                          </div>
                          <div className='k-d-flex single-field-row single-row-60'>
                            <div className={`field-wrapper`}>
                              <DropDownInput
                                label='Group'
                                textField='DisplayName'
                                dataItemKey='Value'
                                name={'uc_ossi_grp'}
                                dataSet={groupTypes}
                                value={
                                  formData?.uc_ossi_grp
                                    ? {
                                        Value: formData?.uc_ossi_grp,
                                        DisplayName: formData?.uc_ossi_grp,
                                      }
                                    : null
                                }
                                handleChange={(e) => {
                                  setFormData((prevState) => ({
                                    ...prevState,
                                    uc_ossi_grp: e.value?.Value,
                                  }));
                                }}
                                defaultItem={{
                                  DisplayName: 'Select Group ...',
                                  Value: '',
                                }}
                                filterable={true}
                              />
                            </div>
                            <div className={`field-wrapper`}>
                              <Field
                                key={'uc_ossi_expected_exec_ms'}
                                id={'uc_ossi_expected_exec_ms'}
                                name={'uc_ossi_expected_exec_ms'}
                                data={formData?.uc_ossi_expected_exec_ms}
                                onChange={handleInputChange}
                                label={'Execution Millisecond'}
                                type={'number'}
                                component={TextInput}
                                renderTypes={
                                  !detailsDataModified ? 'ReadOnly' : ''
                                }
                                optional={false}
                              />
                            </div>
                          </div>
                          <div className='single-field-row k-mt-4'>
                            <div className={`field-wrapper`}>
                              <Field
                                name='uc_ossi_disable_flg'
                                component={InputSwitch}
                                label='Disabled'
                                onLabel='Yes'
                                offLabel='No'
                                id='uc_ossi_disable_flg'
                                checked={formData?.uc_ossi_disable_flg}
                                onChange={handleInputChange}
                                disabled={!detailsDataModified}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </CardBody>
              </Card>
              {detailsDataModified && (
                <Card className='k-mt-3'>
                  <CardHeader>
                    <div className='k-d-flex single-field-row button-form-wrapper k-action-buttons !k-px-0'>
                      <>
                        <Button
                          icon={'cancel'}
                          onClick={() => onExit()}
                          type='button'
                        >
                          Cancel
                        </Button>
                        <SplitButton
                          text='save'
                          themeColor={'primary'}
                          onButtonClick={() =>
                            onSave({ isExit: false, isContinue: true })
                          }
                          onItemClick={handleSaveSplit}
                        >
                          <SplitButtonItem text='Save & Close' />
                        </SplitButton>
                      </>
                    </div>
                  </CardHeader>
                </Card>
              )}
              {testState?.edit && (
                <Card className='k-mt-3 detail-grid-section'>
                  <CardBody className='!k-px-0'>
                    <DesktopLayout
                      handleSelect={handleSelect}
                      selected={selected}
                      detailsDataModified={detailsDataModified}
                      state={testState}
                      shouldUpdate={shouldUpdate}
                      setShouldUpdate={setShouldUpdate}
                    />
                  </CardBody>
                </Card>
              )}
            </div>
          </>
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default AddTestCase;
