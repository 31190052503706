import React from 'react';
import { Button, ChipList, Chip } from '@progress/kendo-react-buttons';
import { BasicContentSlider as ContentSlider, SliderCard } from 'smart-react';
import LookUp from './LookUp';
import { Lookup_Selection_Mode } from '../../../../../constants/applicationConstants';

/**
 * limit to view recently selected items.
 */
const RecentLimit = 20;

/**
 * Lookup mode of test
 * Slider Card of Test lookup
 */
const TestCaseLookupSlider = ({
  show,
  handleSlide,
  setSelectedLookupItems,
  lookupSelectionMode,
  mode,
  testId,
  ...others
}) => {
  const [lookupCheckedItems, setLookupCheckedItems] = React.useState([]);
  const handleDataChange = (event) => {
    setLookupCheckedItems(event.value);
  };

  /**
   * used to set the value of selected lookup item for main test input field
   * @param {Object} e
   */
  const selectLookups = async (e) => {
    e.preventDefault();
    if (lookupCheckedItems?.length > 0) {
      setSelectedLookupItems(lookupCheckedItems);
      setLookupCheckedItems([]);
      handleSlide();
    }
  };

  const children = (
    <SliderCard
      title={'Test Case'}
      handleSlide={handleSlide}
      className='eq-lookup'
    >
      <SliderCard.Body>
        <LookUp
          mode={mode}
          selectLookups={selectLookups}
          setLookupCheckedItems={setLookupCheckedItems}
          lookupCheckedItems={lookupCheckedItems}
          lookupSelectionMode={lookupSelectionMode}
          handleSlide={handleSlide}
          show={show}
          testId={testId}
          {...others}
        />
      </SliderCard.Body>
      <SliderCard.Footer>
        <div className='lookup-footer-wrapper'>
          <ChipList
            size={'medium'}
            data={lookupCheckedItems}
            onDataChange={handleDataChange}
            chip={(props) => (
              <Chip removable={true} {...props} onRemove={() => {}} />
            )}
          />
          {lookupSelectionMode !== Lookup_Selection_Mode.single && (
            <Button
              themeColor={'primary'}
              onClick={selectLookups}
              disabled={!(lookupCheckedItems?.length > 0)}
            >
              SELECT
            </Button>
          )}
        </div>
      </SliderCard.Footer>
    </SliderCard>
  );

  return (
    <div className='lookup-mode'>
      <ContentSlider show={show} children={children} />
    </div>
  );
};

export default TestCaseLookupSlider;
